
<template>
<div>
  <div v-if="!isLoading" class="row account-page g-3">
    <div class="col-md-10">
      <div class="card bg-white overflow-hidden">
        <div class="card-body">
          <div class="">
            <div class="
                profile-user
                position-relative
                d-inline-block
                mx-auto
                mb-4">
              <img
                :src="absoluteUrl(shownUser.image)"
                class="
                  rounded-circle
                  avatar-xl
                  img-thumbnail
                  user-profile-image" alt="user-profile-image" />
            </div>
            <h5 class="fs-16 mb-1">
              {{shownUser.first_name}} {{shownUser.last_name}}
            </h5>
            <p class="mb-0"> <span class="text-capitalize">{{shownUser.role}}</span> / Joined on {{$filters.date(shownUser.created_at)}}</p>
          </div>
        </div>
        <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
      </div>
    </div>
    <div class="col-md-10">
      <div class="row g-3">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-profile />
            </div>
          </div>
        </div>
          <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-information />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-password />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserEditProfile  from "./UserEditProfile.vue"
import UserEditInformation from "./UserEditInformation.vue"
import UserEditPassword from "./UserEditPassword.vue"

export default {
  name: "user-edit",
  components: {
    UserEditProfile,
    UserEditInformation,
    UserEditPassword,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    shownUser(){
      return this.$store.state.userList.user
    },
  },
  created() {
    if(!this.$store.state.userList.roles.length){
        this.$store.dispatch("userList/fetchRoles")
    }
    this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
    .then((response) => {
      if(response.data.success){
        this.isLoading = false
      }else{
        this.$router.push({path: '/error-404'})
      }
    })
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>
